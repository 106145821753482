<template>
  <div>
    <div class="container" style="top: -40px; position: relative; text-shadow: 1px 1px 2px black">
      <h3 style="text-align: Center; margin-top: 20px; font-size: 22px; text-decoration: underline">
        CUENTA
        <button @click="logout" style="background: yellow; border-radius: 11px; width: 76px; padding: 0; height: 27px; position: absolute; right: 26px; top: 80px"><span style="position: relative; top: 0px; font-size: 14px">Salir</span> <themify-icon style="position: relative; top: 4px" icon="close" /></button>
        <div style="clear: both"></div>
      </h3>
      <div style="max-width: 480px; margin: 0 auto; position: relative"></div>
      <label>Correo (*)(**)</label>
      <input type="text" v-model="correoModificado" ref="correoModificado" @keyup="mobileFix" />
      <label>Contraseña (*)(**)</label>
      <input type="password" v-model="password" ref="password" @keyup="mobileFix" autocomplete="new-password" />
      <label>Usuario(*)</label>
      <input type="text" v-model="usuarioModificado" ref="usuarioModificado" @keyup="mobileFix" autocomplete="off" />
      <label>Nombre</label>
      <input type="text" v-model="nombreModificado" ref="nombreModificado" @keyup="mobileFix" />
      <label>Presentación</label>
      <input type="text" v-model="presentacionModificada" ref="presentacionModificada" @keyup="mobileFix" />
      <label style="position: relative; bottom: -8px">Avatar</label>
      <div class="preview">
        <img ref="preview" @click="upload" :src="avatarModificado" alt="" />
      </div>
      <input ref="uploadInput" type="file" @change="changeAvatar" v-show="false" />

      <button @click="upload">Cambiar Avatar</button>
      <ul id="modificaciones">
        <li style="color: red" v-if="cambioCorreo && cambioCorreoInvalido">Correo Invalido</li>
        <li v-if="cambioCorreo && !cambioCorreoInvalido">Cambiar Correo (Esto cerrara la sesión) <button class="modificacion-button" @click="resetCorreo()">x</button></li>

        <li style="color: red" v-if="cambioUsername && cambioUsernameInvalido">Username Invalido</li>
        <li v-if="cambioUsername && !cambioUsernameInvalido">Cambiar Username <button class="modificacion-button" @click="resetUsername()">x</button></li>

        <li v-if="cambioPassword && cambioPasswordInvalido" style="color: red">Contraseña Invalido</li>
        <li v-if="cambioPassword && !cambioPasswordInvalido">Cambiar Contraseña <button class="modificacion-button" @click="resetPassword()">x</button></li>

        <li v-if="cambioNombre">Cambiar Nombre <button class="modificacion-button" @click="resetNombre()">x</button></li>

        <li v-if="cambioPresentacion">Cambiar Presentación <button class="modificacion-button" @click="resetPresentacion()">x</button></li>

        <li v-if="cambioAvatar">Cambiar Avatar <button class="modificacion-button" @click="resetAvatar()">x</button></li>
      </ul>
      <button @click="modificar" :disabled="esModificacionInvalida"><themify-icon icon="save" /> &nbsp; Aplicar Cambios</button>

      <div v-if="credentials !== null" class="info">
        <br />

        <label>UID: {{ credentials.uid }} </label>
        <br />
        <label>ÚLTIMO ACCESO: {{ new Date(credentials.lastLoginAt * 1).toLocaleString() }}</label
        ><br />
        <label>(*) Campo obligatorio</label>
        <br />
        <label>(**) Modificación requiere login reciente</label>
        <br />

        <br />
        <h3 style="text-align: Center; margin-top: 20px; font-size: 18px">CONFIGURACIÓN</h3>

        <button @click="clearStorage"><themify-icon icon="na" /> &nbsp; Eliminar la Data Local (*)</button>&nbsp;
        <button @click="eliminar"><themify-icon icon="na" /> &nbsp; Eliminar Cuenta (*)</button>
        <button style="padding: 3px; height: 26px; width: 140px; position: relative; top: -1px; text-align: center" @click="clickSelect">
          <span style="pointer-events: none; color: black; font-size: 10px; padding: 0; position: absolute; top: 4px; left: 3px">SEPARADOR CSV</span> <br />
          <select v-model="csvSeparator" style="position: absolute; background: transparent; top: -5px; color: black; left: 90px; width: 50px; height: 36px; outline: none; text-align: center">
            <option>,</option>
            <option>;</option>
            <option>|</option>
            <option>TAB</option>
          </select>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import pjson from '../../package.json';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, signOut, deleteUser, sendPasswordResetEmail, updateEmail, updatePassword, signInWithPopup } from 'firebase/auth';
import { mapState } from 'vuex';
import ThemifyIcon from 'vue-themify-icons';
import { GoogleAuthProvider } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, doc, setDoc, getFirestore, updateDoc, deleteDoc, runTransaction } from 'firebase/firestore';

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[A-Z][A-Z]+/i;
export default {
  name: 'Config',
  components: {
    ThemifyIcon,
  },
  data: function () {
    return {
      csvSeparator: ',',
      version: pjson.version,
      correo: '',
      correoModificado: '',
      usuarioModificado: '',
      nombreModificado: '',
      presentacionModificada: '',
      avatarModificado: '',
      password: '',
      usermsg: '',
      username: '',
      avatarBytes: null,
    };
  },
  mounted: function () {
    this.correoModificado = this.credentials.email;
    this.usuarioModificado = this.credentials.username;
    this.nombreModificado = this.credentials.nombre;
    this.presentacionModificada = this.credentials.presentacion;
    this.avatarModificado = this.credentials.avatar !== null ? this.credentials.avatar : '/img/avatar.jpg';
    this.usermsg = this.credentials.usermsg;
    this.username = this.credentials.username;
    this.csvSeparator = this.getLocal('csvSeparator') || this.csvSeparator;

    this.setLoading(false);
  },
  watch: {
    usuarioModificado: function () {
      this.usuarioModificado = this.usuarioModificado.toLowerCase();
    },
    csvSeparator: function () {
      this.setLocal('csvSeparator', this.csvSeparator);
    },
  },
  computed: {
    ...mapState(['config', 'credentials', 'uid']),
    cambioCorreo: function () {
      return this.correoModificado.trim() !== (this.credentials === null ? '' : this.credentials.email);
    },
    cambioPassword: function () {
      return this.password.length > 0;
    },
    cambioNombre: function () {
      return this.nombreModificado.trim() !== (this.credentials === null ? '' : this.credentials.nombre);
    },
    cambioPresentacion: function () {
      return this.presentacionModificada.trim() !== (this.credentials === null ? '' : this.credentials.presentacion);
    },
    cambioUsername: function () {
      return this.usuarioModificado.trim() !== (this.credentials === null ? '' : this.credentials.username);
    },
    cambioUsernameInvalido: function () {
      let match = this.usuarioModificado.trim().match(/^[a-zñ\.0-9áéíóúü]+$/gi);
      return match === null || match[0] !== this.usuarioModificado.trim();
    },
    cambioCorreoInvalido: function () {
      let match = this.correoModificado.trim().match(emailRegex);
      return match === null || match[0] !== this.correoModificado.trim();
    },
    cambioPasswordInvalido: function () {
      return this.password.length > 0 && !this.config.validatePassword(this.password);
    },
    cambioAvatar: function () {
      return this.avatarBytes !== null;
    },
    esModificacionInvalida: function () {
      return (
        this.credentials !== null &&
        (((this.cambioCorreoInvalido || this.cambioPasswordInvalido || this.cambioUsernameInvalido) && (this.cambioPassword || this.cambioUsername || this.cambioCorreo)) || //
          (!this.cambioUsername && !this.cambioPassword && !this.cambioCorreo && !this.cambioNombre && !this.cambioPresentacion && this.credentials.avatar === this.avatarModificado)) //
      );
    },
    estaAutenticado: function () {
      return this.credentials !== null && this.credentials.emailVerified;
    },
  },
  methods: {
    mobileFix() {
      this.nombreModificado = this.$refs.nombreModificado.value;
      this.presentacionModificada = this.$refs.presentacionModificada.value;
      this.usuarioModificado = this.$refs.usuarioModificado.value;
      this.correoModificado = this.$refs.correoModificado.value;
      this.password = this.$refs.password.value;
    },
    resetAvatar() {
      this.avatarModificado = this.credentials.avatar;
      this.avatarBytes = null;
    },
    resetPassword() {
      this.password = '';
    },
    resetUsername() {
      this.usuarioModificado = this.credentials.username;
    },
    resetCorreo() {
      this.correoModificado = this.credentials ? this.credentials.email : '';
    },
    resetNombre() {
      this.nombreModificado = this.credentials ? (this.credentials.nombre === null ? '' : this.credentials.nombre) : '';
    },
    resetPresentacion() {
      this.presentacionModificada = this.credentials ? this.credentials.presentacion : '';
    },
    async providerAuth(provider) {
      this.setLoading(true);
      const auth = getAuth();
      this.notification();
      try {
        await signInWithPopup(auth, provider);
        this.notification('Login Aceptado');
      } catch (error) {
        this.notification(error, provider.providerId + ' Auth Error');
      }
      this.setLoading(false);
    },
    async googleAuth() {
      this.providerAuth(new GoogleAuthProvider());
    },
    async login() {
      this.setLoading(true);
      const auth = getAuth();
      this.notification();
      try {
        const credentials = await signInWithEmailAndPassword(auth, this.correo, this.password);
        if (!credentials.user.emailVerified) {
          await sendEmailVerification(auth.currentUser);
          this.notification({
            code: 'auth/not-email-verified',
            message: 'Revise su correo (' + this.correo.trim() + '), primero tiene que proceder con la verificación  (OJO que puede estar en la carpeta de SPAM)...',
          });
          await signOut(auth);
        } else {
          this.notification('Login Aceptado');
          this.password = '';
        }
      } catch (error) {
        await signOut(auth);
        this.notification(error, 'Login Error');
      }
      this.setLoading(false);
    },
    async logout() {
      this.setLoading(true);
      const auth = getAuth();
      this.notification();
      try {
        await signOut(auth);
        this.notification('Cerró sesión');
      } catch (error) {
        this.notification(error, 'Logout Error');
      }
      this.setLoading(false);
    },
    async register() {
      this.setLoading(true);
      const auth = getAuth();
      this.notification();
      try {
        await createUserWithEmailAndPassword(auth, this.correo, this.password);
        this.notification('Registro Exitoso de ' + this.correo);
        await sendEmailVerification(auth.currentUser);
        this.notification({
          code: 'auth/not-email-verified',
          message: 'Revise su correo (' + this.correo + '), le acabamos de enviar un correo de verificación  (OJO que puede estar en la carpeta de SPAM)...',
        });
        await signOut(auth);
        this.password = '';
      } catch (error) {
        await signOut(auth);
        this.notification(error, 'Register Error');
      }
      this.setLoading(false);
    },
    async upload() {
      this.$refs.uploadInput.click();
    },
    async reestablecer() {
      this.setLoading(true);
      const auth = getAuth();
      this.notification();
      try {
        await sendPasswordResetEmail(auth, this.correo);
        this.notification('Se ha enviado un correo de restablecimiento del password');
      } catch (error) {
        this.notification(error, 'Reset Password Error');
      }
      this.setLoading(false);
    },
    async modificar() {
      this.setLoading(true);
      const auth = getAuth();
      const db = getFirestore();
      this.notification();
      if (this.cambioAvatar) {
        const storage = getStorage();
        const storageRef = ref(storage, this.credentials.uid + '/avatar.jpg');
        try {
          const snapshot = await uploadBytes(storageRef, this.avatarBytes);
          const url = await getDownloadURL(snapshot.ref);
          await updateDoc(doc(collection(db, 'users'), this.credentials.uid), {
            avatar: url,
          });
          this.credentials.avatar = url;
          this.avatarModificado = url;
          this.avatarBytes = null;
          this.notification('Avatar Modificado');
        } catch (error) {
          this.notification(error, 'Upload Avatar Error');
        }
      }
      if (this.cambioPassword) {
        try {
          await updatePassword(auth.currentUser, this.password);
          this.notification('Password Modificado');
          this.password = '';
        } catch (error) {
          this.notification(error, 'Set Password Error');
        }
      }
      if (this.cambioNombre) {
        try {
          this.nombreModificado = this.nombreModificado.trim();
          await updateDoc(doc(collection(db, 'users'), this.credentials.uid), {
            nombre: this.nombreModificado,
          });
          this.credentials.nombre = this.nombreModificado;
          this.notification('Nombre Modificado');
        } catch (error) {
          this.notification(error);
        }
      }
      if (this.cambioPresentacion) {
        try {
          this.presentacionModificada = this.presentacionModificada.trim();
          await updateDoc(doc(collection(db, 'users'), this.credentials.uid), {
            presentacion: this.presentacionModificada,
          });
          this.credentials.presentacion = this.presentacionModificada;
          this.notification('Nombre Modificado');
        } catch (error) {
          this.notification(error, 'Set Presentación Error');
        }
      }
      if (this.cambioUsername) {
        const username = this.usuarioModificado.trim();
        try {
          await runTransaction(db, async (transaction) => {
            const usernameExists = await (await transaction.get(doc(db, 'usernames', username))).exists();
            if (usernameExists) {
              throw new Error(`El nombre de usuario ${username} ya esta en uso`);
            }
            const data = { ...this.credentials, username };
            await transaction.set(doc(collection(db, 'users'), this.credentials.uid), data);
            await transaction.delete(doc(collection(db, 'usernames'), this.credentials.username));
            await transaction.set(doc(collection(db, 'usernames'), username), {
              uid: this.credentials.uid,
            });
            this.usuarioModificado = username;
            this.credentials.username = username;
            this.notification('Username Modificado');
          });
        } catch (error) {
          this.notification(error, 'Set Username Error');
        }
      }
      if (this.cambioCorreo) {
        try {
          await updateEmail(auth.currentUser, this.correoModificado.trim());
          this.notification('Correo Modificado');
          await sendEmailVerification(auth.currentUser);
          await signOut(auth);
          this.notification({
            code: 'auth/not-email-verified',
            message: 'Revise su correo (' + this.correoModificado.trim() + '), le acabamos de enviar un correo de verificación  (OJO que puede estar en la carpeta de SPAM)...',
          });
          this.notification({
            code: 'auth/rollback-email-change',
            message: 'Si desea anular la modificación del correo revise su correo antiguo, le hemos enviado instrucciones.',
          });
          this.password = '';
        } catch (error) {
          this.notification(error, 'Set Correo Error');
        }
      }
      this.setLoading(false);
      this.$forceUpdate();
    },
    async clearStorage() {
      this.notification(this.credentials.uid);
      for (var i in localStorage) {
        if (i.startsWith(this.credentials.uid)) {
          localStorage.removeItem(i);
        }
      }
    },
    clickSelect(evt) {
      const select = evt.target.children[2];
      select.selectedIndex = (select.selectedIndex + 1) % select.children.length;
    },
    async eliminar() {
      const db = getFirestore();
      const auth = getAuth();
      this.notification();
      try {
        const username = this.credentials.username;
        const uid = this.credentials.uid;
        let removedData = {};
        await runTransaction(db, async (transaction) => {
          removedData = {
            usuario: await transaction.get(doc(collection(db, 'users'), uid)),
            username: await transaction.get(doc(collection(db, 'usernames'), username)),
          };
          removedData.username = removedData.username.data();
          removedData.usuario = removedData.usuario.data();
          await transaction.delete(doc(db, 'users', uid));
          await transaction.delete(doc(db, 'usernames', username));
        });
        await deleteUser(auth.currentUser).catch(async (err) => {
          this.notification(err);
          await setDoc(doc(collection(db, 'users'), uid), removedData.usuario);
          await setDoc(doc(collection(db, 'usernames'), username), removedData.username);
        });
        if (auth.currentUser === null) {
          this.notification('Usuario eliminado');
        }
      } catch (error) {
        this.notification(error, 'Delete Account Error');
      }
    },
    changeAvatar() {
      const inputFile = this.$refs.uploadInput;
      this.setLoading(true);
      const reader = new FileReader();
      try {
        reader.readAsDataURL(inputFile.files[0]);
        reader.onload = (event) => {
          const size = 256;
          const img = new Image();
          img.src = event.target.result;
          (img.onload = () => {
            const elem = document.createElement('canvas');
            elem.width = size;
            elem.height = size;
            let width = img.width;
            let height = img.height;
            let offsetWidth = 0;
            let offsetHeight = 0;
            let zoomWidth = size / width;
            let zoomHeight = size / height;
            if (zoomWidth > zoomHeight) {
              width = size;
              height = height * zoomWidth;
              offsetHeight = (size - height) / 2;
            } else {
              height = size;
              width = width * zoomHeight;
              offsetWidth = (size - width) / 2;
            }
            const ctx = elem.getContext('2d');
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(img, offsetWidth, offsetHeight, width, height);

            ctx.canvas.toBlob(
              (blob) => {
                this.avatarBytes = blob;
                this.avatarModificado = window.URL.createObjectURL(blob);
              },
              'image/jpeg',
              1
            );
            this.setLoading(false);
          }),
            (reader.onerror = (error) => {
              this.notification(error);
            });
        };
      } catch (error) {
        this.setLoading(false);
        this.avatarModificado = this.credentials.avatar;
        this.avatarBytes = null;
      }
    },
  },
};
</script>
<style scoped>
label {
  position: relative;
  bottom: -7px;
}
button {
  margin-bottom: 6px;
  margin-right: 6px;
  height: 32px !important;
}
</style>
